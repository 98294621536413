<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent title="О проекте" />
        <section class="section open">
          <EditorJSComponent :text="JSON.parse(scAbout.description)" v-if="scAbout && scAbout.description" />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "SocialControlAbout",
  components: {
    EditorJSComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ store }) {
    await store.dispatch("GET_SC_ABOUT");
  },
  computed: {
    scAbout() {
      return this.$store.state?.sc_about_page;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
